import gql from "graphql-tag";

export const ordersGql = gql`
  query Orders($input: OrdersFilterInput) {
    orders(input: $input) {
      ... on OrderResults {
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
        }
        results {
          id
          created
          targetDate
          status {
            id
            name
            key
          }
          items {
            id
            title
            thumbnail
          }
          quotation {
            id
            created
            numOfVideos
            hireStrategist
            status {
              id
              key
            }
          }
          jobs {
            id
          }
          paymentLink
          creatorsLink
          shipmentInstructions
          scriptLink
          videoOutputLink
          feedbackLink
        }
      }

      ... on ResponseErrors {
        errors {
          code
          displayMessage
          message
        }
      }
    }
  }
`;
