import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "layout-empty-page flex justify-center items-center flex-col" }
const _hoisted_2 = { class: "mt-6 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_typography_title, {
      level: 2,
      class: "layout-empty-page__title text-center"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.title), 1)
      ]),
      _: 1
    }),
    (_ctx.subtitle)
      ? (_openBlock(), _createBlock(_component_a_typography_paragraph, {
          key: 0,
          class: "layout-empty-page__subtitle text-center"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.subtitle), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}