
import Card from "@/shared/components/Card.vue";
import PageLayout from "@/shared/components/Layouts/PageLayout.vue";
import OrdersTable from "@/shared/components/Orders/OrdersTable.vue";
import { useOrders } from "@/shared/composables/useOrders";
import { OrderStatus } from "@/shared/types/OrderStatus";
import ProfileBackground from "@/web/views/Profile/ProfileBackground.vue";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  props: {},
  setup() {
    const { t } = useI18n();

    const {
      orders,
      ordersLoading,
      ordersHasNextPage,
      ordersFetchMore,
      ordersFetchMoreLoading,
    } = useOrders();

    return {
      t,
      OrderStatus,
      orders,
      ordersLoading,
      ordersHasNextPage,
      ordersFetchMore,
      ordersFetchMoreLoading,
    };
  },
  methods: {},
  components: {
    PageLayout,
    ProfileBackground,
    Card,
    OrdersTable,
  },
});
