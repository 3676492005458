
import { Orders_orders_OrderResults_results } from "@/api/order/__generated__/Orders";
import OrdersTableRow from "@/shared/components/Orders/OrdersTableRow.vue";
import { PropType, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import LayoutEmptyPage from "@/shared/components/Layouts/LayoutEmptyPage.vue";
import { useRouter } from "vue-router";
import routeNames from "@/web/router/routeNames";

export default defineComponent({
  props: {
    orders: Array as PropType<Orders_orders_OrderResults_results[]>,
    loading: Boolean,
    hasNextPage: Boolean,
    loadMoreLoading: Boolean,
  },
  emits: ["on-load-more"],
  setup() {
    const { t } = useI18n();
    const router = useRouter();

    const redirectToHome = () => {
      router.push({ name: routeNames.home });
    };

    return { t, redirectToHome };
  },
  components: {
    OrdersTableRow,
    LayoutEmptyPage,
  },
});
