
import { Orders_orders_OrderResults_results } from "@/api/order/__generated__/Orders";
import { useOrderStatusHelper } from "@/shared/composables/useOrderStatusHelper";
import { formatDate } from "@/shared/utils/date";
import { PropType, computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { OrderStatus } from "@/shared/types/OrderStatus";
import { OrderStatusActionHandler } from "@/shared/utils/orderStatusHelper";
import { useRedirectHelper } from "@/shared/composables/useRedirectHelper";
import { getOrderName } from "@/shared/utils/orderUtils";

export default defineComponent({
  props: {
    order: Object as PropType<Orders_orders_OrderResults_results>,
  },
  setup(props) {
    const { t } = useI18n();
    const { redirectToOrderDetails } = useRedirectHelper();

    const { currentStepActions, targetDateString, currentStepData } =
      useOrderStatusHelper(computed(() => props.order ?? null));

    const redirectToOrderDetail = () => {
      if (!props.order?.id) {
        return;
      }

      redirectToOrderDetails({
        orderId: props.order.id,
      });
    };

    return {
      t,
      formatDate,
      currentStep: computed(() => {
        return currentStepData.value?.title ?? props.order?.status?.name;
      }),
      currentStepColor: computed(() => {
        switch (props.order?.status?.key) {
          case OrderStatus.DONE:
            return "green";
          default:
            return "blue";
        }
      }),
      currentStepAction: computed<OrderStatusActionHandler | null | undefined>(
        () => {
          // No Actions
          if (
            [OrderStatus.DONE, OrderStatus.CANCELED].includes(
              props.order?.status?.key as OrderStatus
            )
          ) {
            return null;
          }

          return currentStepActions.value.at(0);
        }
      ),
      targetDateString,
      redirectToOrderDetail,
      getOrderName,
    };
  },
});
