import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "orders-table-row__id" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createBlock(_component_a_row, {
    onClick: _ctx.redirectToOrderDetail,
    class: "orders-table-row cursor-pointer py-4 m-0 text-center",
    align: "middle",
    gutter: 8
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_col, {
        span: 4,
        class: "pl-4"
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.getOrderName({ order: _ctx.order })), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_a_col, { span: 2 }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.order?.quotation?.numOfVideos ?? _ctx.order?.jobs?.length ?? 0), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_a_col, { span: 5 }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.formatDate(_ctx.order?.created, "dateWithNoTimeInWords_dayFirst")), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_a_col, { span: 5 }, {
        default: _withCtx(() => [
          (_ctx.order?.targetDate)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.targetDateString), 1))
            : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.t("-")), 1))
        ]),
        _: 1
      }),
      _createVNode(_component_a_col, { span: 4 }, {
        default: _withCtx(() => [
          _createElementVNode("span", {
            class: _normalizeClass(["orders-table-row__status", [`orders-table-row__status--${_ctx.currentStepColor}`]])
          }, _toDisplayString(_ctx.currentStep), 3)
        ]),
        _: 1
      }),
      _createVNode(_component_a_col, {
        span: 4,
        class: "flex pr-4"
      }, {
        default: _withCtx(() => [
          (_ctx.currentStepAction)
            ? (_openBlock(), _createBlock(_component_a_button, {
                key: 0,
                type: "primary",
                onClick: _withModifiers(_ctx.currentStepAction.handler, ["stop"]),
                class: "orders-table-row__button"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.currentStepAction.label), 1)
                ]),
                _: 1
              }, 8, ["onClick"]))
            : (_openBlock(), _createBlock(_component_a_button, {
                key: 1,
                type: "primary",
                onClick: _withModifiers(_ctx.redirectToOrderDetail, ["stop"]),
                class: "orders-table-row__button"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("View")), 1)
                ]),
                _: 1
              }, 8, ["onClick"]))
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["onClick"]))
}