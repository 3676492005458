import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "orders-page__container pt-8 pb-16" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProfileBackground = _resolveComponent("ProfileBackground")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_OrdersTable = _resolveComponent("OrdersTable")!
  const _component_Card = _resolveComponent("Card")!
  const _component_PageLayout = _resolveComponent("PageLayout")!

  return (_openBlock(), _createBlock(_component_PageLayout, {
    class: "orders-page",
    onOnBack: _ctx.$router.back,
    "back-button": ""
  }, {
    complexBackground: _withCtx(() => [
      _createVNode(_component_ProfileBackground)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Card, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_typography_title, { level: 4 }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t("Previous Orders")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_OrdersTable, {
              orders: _ctx.orders,
              loading: _ctx.ordersLoading,
              "has-next-page": _ctx.ordersHasNextPage,
              "load-more-loading": _ctx.ordersFetchMoreLoading,
              onOnLoadMore: _ctx.ordersFetchMore
            }, null, 8, ["orders", "loading", "has-next-page", "load-more-loading", "onOnLoadMore"])
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["onOnBack"]))
}