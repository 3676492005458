import { useQuery } from "@vue/apollo-composable";
import { ordersGql } from "@/api/order/orders";
import { computed, ref } from "vue";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import {
  Orders,
  OrdersVariables,
  Orders_orders,
  Orders_orders_OrderResults,
} from "@/api/order/__generated__/Orders";
import { HideIfErrorCode } from "@/shared/utils/graphql/errorHandler";
import { DEFAULT_PAGE_SIZE } from "@/shared/utils/constants";
import get from "lodash/get";

export type UseOrderOptions = {
  hideError: boolean;
  pageSize: number;
};

const DEFAULT_OPTIONS: UseOrderOptions = {
  hideError: false,
  pageSize: DEFAULT_PAGE_SIZE,
};

export const useOrders = (options?: Partial<UseOrderOptions>) => {
  const opts = {
    ...DEFAULT_OPTIONS,
    ...options,
  };

  const {
    result: ordersResult,
    loading: ordersLoading,
    fetchMore,
  } = useQuery<Orders, OrdersVariables>(ordersGql, () => ({
    input: {
      pagination: {
        pageSize: opts.pageSize,
        after: null,
      },
    },
  }));

  const ordersParsedResponse = computed(
    () =>
      parseGqlResponse<Orders_orders_OrderResults>(
        "OrderResults",
        ordersResult.value,
        opts.hideError ? HideIfErrorCode.ALL_ERRORS : null
      ).data
  );

  const orders = computed(() => {
    return ordersParsedResponse.value?.results;
  });

  //   ===== Fetch More =====
  const ordersPageInfo = computed(() => ordersParsedResponse.value?.pageInfo);

  const ordersFetchMoreLoading = ref(false);

  const ordersFetchMore = async () => {
    if (ordersPageInfo.value?.hasNextPage && ordersPageInfo.value.endCursor) {
      ordersFetchMoreLoading.value = true;

      await fetchMore({
        variables: {
          input: {
            pagination: {
              pageSize: opts.pageSize,
              after: ordersPageInfo.value.endCursor,
            },
          },
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const updatedOrdersResults = {
            ...previousResult.orders,
            ...fetchMoreResult?.orders,

            // Concatenate results array
            results: [
              ...get(previousResult.orders, "results", []),
              ...get(fetchMoreResult?.orders, "results", []),
            ],
          };

          return {
            orders: updatedOrdersResults as Orders_orders,
          };
        },
      });
    }

    ordersFetchMoreLoading.value = false;
  };

  return {
    orders,
    ordersLoading,
    ordersFetchMore,
    ordersFetchMoreLoading,
    ordersHasNextPage: computed(() => ordersPageInfo.value?.hasNextPage),
  };
};
