/**
 * See graphql schema for possible values
 */
export enum OrderStatus {
  /**
   * Represents no latest order
   */
  _NULL = "_NULL",
  NEW = "NEW",
  APPROVED = "APPROVED",
  PAID = "PAID",
  QA1 = "QA1",
  QA1_EDIT = "QA1_EDIT",
  QA1_APPROVED = "QA1_APPROVED",
  /** For K4A Choosing Performer-Creators */
  SELECTING_CREATOR = "SELECTING_CREATOR",
  /** For Client Choosing Performer-Creator */
  SELECT_CREATOR = "SELECT_CREATOR",
  SHIP_PRODUCT = "SHIP_PRODUCT",
  IDEATION = "IDEATION",
  PRODUCTION = "PRODUCTION",
  QA = "QA",
  CLIENT_REVIEW = "CLIENT_REVIEW",
  CLIENT_FEEDBACK = "CLIENT_FEEDBACK",
  DONE = "DONE",
  CANCELED = "CANCELED",
}
