import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "orders-table" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "orders-table__container" }
const _hoisted_4 = { class: "orders-table__table" }
const _hoisted_5 = { class: "font-bold" }
const _hoisted_6 = { class: "font-bold" }
const _hoisted_7 = { class: "font-bold" }
const _hoisted_8 = { class: "font-bold" }
const _hoisted_9 = { class: "font-bold" }
const _hoisted_10 = {
  key: 0,
  class: "text-center mt-8"
}
const _hoisted_11 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_OrdersTableRow = _resolveComponent("OrdersTableRow")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_LayoutEmptyPage = _resolveComponent("LayoutEmptyPage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loading && !_ctx.loadMoreLoading)
      ? (_openBlock(), _createBlock(_component_a_skeleton, {
          key: 0,
          active: ""
        }))
      : (!!_ctx.orders?.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_a_row, {
                  align: "middle",
                  gutter: 8,
                  class: "orders-table__header py-4 m-0 text-center"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, {
                      span: 4,
                      class: "pl-4"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.t("ORDER #")), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 2 }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.t("VIDEOS")), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 5 }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.t("ORDER CREATED")), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 5 }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.t("ORDER COMPLETION")), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 4 }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.t("STATUS")), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, {
                      span: 4,
                      class: "pr-4"
                    })
                  ]),
                  _: 1
                }),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orders, (order) => {
                  return (_openBlock(), _createBlock(_component_OrdersTableRow, {
                    order: order,
                    key: order.id
                  }, null, 8, ["order"]))
                }), 128))
              ])
            ]),
            (_ctx.hasNextPage)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createVNode(_component_a_button, {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-load-more'))),
                    loading: _ctx.loadMoreLoading
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("Load more")), 1)
                    ]),
                    _: 1
                  }, 8, ["loading"])
                ]))
              : _createCommentVNode("", true)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_11, [
            _createVNode(_component_LayoutEmptyPage, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_typography_title, {
                  level: 4,
                  class: "orders-table__empty mb-8"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("Choose the kind of videos you like us to do for your business.")), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_button, {
                  type: "primary",
                  onClick: _ctx.redirectToHome
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("Order Video")), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]))
  ]))
}